/* eslint-disable @next/next/no-img-element */
import { IFeaturedPost, TAGS } from 'utils/graphql'
import { formatPostByDate } from 'utils/utils'
import Link from 'next/link'
import React, { CSSProperties, useEffect, useRef } from 'react'
import styles from './Post.module.scss'

interface TriplePostProps {
	post: IFeaturedPost
	style?: CSSProperties
}

export const Post: React.FC<TriplePostProps> = (props) => {
	const excerptRef = useRef<HTMLDivElement>(null)
	const { post } = props

	useEffect(() => {
		if (excerptRef.current) {
			excerptRef.current.innerHTML = post.excerpt?.split('<div')[0] || ''
		}
	}, [post.excerpt])

	const actual_tags = post.tags?.edges.filter((it) => TAGS.includes(it.node.name.toLowerCase()))

	return (
		<Link
			key={`post-${post.id}`}
			href={`/blog/${post.slug}`}
			className={styles.post}
			style={props.style}
		>
			<img
				className={styles.img}
				src={post.featuredImage?.node.sourceUrl || 'https://eightsleep.imgix.net/logo_114f1659-31b5-4730-8481-ea9d6b567b4a.png?v=1675807580'}
				alt={post.featuredImage?.node.altText || 'Empty image'}
			/>
			<span>
				{actual_tags && actual_tags[0] ? (
					<div className={styles.tag}>
						<span>{actual_tags[0].node.name}</span>
					</div>
				) : (
					<></>
				)}
				<h3 className={styles.h3}>{post.title}</h3>
				{post.excerpt ? (
					<span
						className={styles.excerpt}
						ref={excerptRef}
					/>
				) : (
					<></>
				)}
				<small className={styles.date}>{formatPostByDate(post.date)}</small>
			</span>
		</Link>
	)
}
