import { FC } from 'react'
import { ImgBackground } from 'components/basic/Img'
import cx from 'classnames'
import styles from './HomeHero.module.scss'
import { Button } from 'components/Phantom/Button'

export interface HeroProps {
	imgMobile: string
	imgDesktop: string
	href: string
	heading: string
	subtext?: string
}

export const HomeHero: FC<HeroProps> = (props) => {
	return (
		<ImgBackground
			src={props.imgMobile}
			alt={''}
			sources={[{ mediaQuery: '(min-width: 600px)', src: props.imgDesktop }]}
			className={styles.container}
		>
			<div className={cx('inner-paddings-x', styles.hero_content)}>
				{props.subtext ? <p className={cx('light', styles.subtext)}>{props.subtext}</p> : <></>}
				<h1 className={cx('light', styles.heading)}>{props.heading}</h1>
				<div className={styles.hero_cta_c}>
					<Button.White
						id={'hero_cta'}
						href={props.href}
						className={styles.hero_cta}
					>
						Read more
					</Button.White>
				</div>
			</div>
		</ImgBackground>
	)
}
