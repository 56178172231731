import { ApolloClient, InMemoryCache, createHttpLink, gql } from '@apollo/client'

const HIDDEN_TAG_ID = 'dGVybToxMzc0'

export interface IImage {
	altText: string
	sourceUrl: string
}

export interface INodeImage {
	node: IImage
}
export interface INodeImage {
	node: IImage
}

// must be consistent with Wordpress database nicename / username
export type AuthorNames =
	| 'eightsleep'
	| 'engineeringteam'
	| 'nicolemoyen'
	| 'eightguestwriter'
	| 'eightmatteofranceschetti'
	| 'kennytotten'
	| 'toketemueight'
	| 'mareadman'
	| 'christophereight'
	| 'angel'
	| 'daveheinz'
	| 'brianschiffer'
	| 'davidhe'
	| 'eightsleepresearchteam'

export const TAGS = ['wellness', 'sleep', 'fitness', 'science', 'membership']

export interface IAuthor {
	name: string
	slug?: string
	avatar: string
	bio: string
	faceboook?: string
	twitter?: string
	instagram?: string
	posts?: { edges: { node: IFeaturedPost }[] }
}

export type AuthorList = {
	[key in AuthorNames]: IAuthor
}

export interface AuthorNode {
	node: IAuthor
}

export interface IFeaturedPost {
	id: string
	title: string
	date: string
	modified
	slug: string
	content: string
	excerpt?: string
	featuredImage: INodeImage
	tags?: any
}

export const ALL_POSTS_QUERY = gql`
	query GetAllPosts($after: String) {
		posts(first: 100, after: $after, where: { orderby: { field: DATE, order: DESC } }) {
			edges {
				node {
					id
					slug
				}
				cursor
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
			}
		}
	}
`

export const getFeaturedTagPostsQuery = (tag: string, hiddenOk = false) => gql`
	query GetAllPosts($after: String) {
		posts(first: 100, after: $after, where: {
			orderby: { field: DATE, order: DESC },
			${hiddenOk ? '' : `tagNotIn: "${HIDDEN_TAG_ID}"`},
			tagSlugIn: ["${tag}"]
		}) {
			edges {
				node {
					id
					title
					slug
					excerpt
					featuredImage {
						node {
							altText
							sourceUrl
						}
					}
					date
				}
				cursor
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
			}
		}
	}
`

export const FEATURED_POSTS_QUERY = gql`
	{
		posts(first: 3, where: { orderby: { field: DATE, order: DESC }, nameIn: ["improved-sleep-quality", "optimal-sleeping-temperature", "accessing-a-deep-state-of-rest-with-nsdr"] }) {
			edges {
				node {
					id
					title
					slug
					featuredImage {
						node {
							altText
							sourceUrl
						}
					}
					date
					content(format: RENDERED)
				}
			}
		}
	}
`

export const LATEST_POSTS_QUERY = gql`
	{
		posts(first: 3, where: { orderby: { field: DATE, order: DESC }, tagNotIn: "${HIDDEN_TAG_ID}", tagSlugIn: ["wellness", "sleep", "fitness", "science", "membership"] }) {
			edges {
				node {
					id
					title
					slug
					excerpt
					date
					featuredImage {
						node {
							altText
							sourceUrl
						}
					}
					content(format: RENDERED)
				}
			}
		}
	}
`

export const POST_QUERY = gql`
	query GetPostBySlug($slug: String) {
		postBy(slug: $slug) {
			id
			title
			slug
			excerpt
			featuredImage {
				node {
					altText
					sourceUrl
				}
			}
			date
			modified
			content(format: RENDERED)
			author {
				node {
					name
					slug
					posts(first: 4, where: { orderby: { field: DATE, order: DESC }, tagNotIn: "${HIDDEN_TAG_ID}", tagSlugIn: ["wellness", "sleep", "fitness", "science", "membership"] }) {
						edges {
							node {
								title
								slug
								excerpt
								date
								featuredImage {
									node {
										altText
										sourceUrl
									}
								}
								tags {
									edges {
										node {
											name
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export const LATEST_POSTS_BY_AUTHOR_QUERY = gql`
	query GetLatestPostsByAuthor($author: String) {
		posts(first: 3, where: { authorName: $author, orderby: { field: DATE, order: DESC }, tagNotIn: "${HIDDEN_TAG_ID}", tagSlugIn: ["wellness", "sleep", "fitness", "science", "membership"] }) {
			edges {
				node {
					id
					slug
					title
					excerpt
					date
					featuredImage {
						node {
							altText
							sourceUrl
						}
					}
				}
			}
		}
	}
`

export const client = new ApolloClient({
	ssrMode: true,
	link: createHttpLink({
		uri: 'https://eightsleepcom.wpcomstaging.com/graphql',
		credentials: 'same-origin',
	}),
	cache: new InMemoryCache(),
})
