/* eslint-disable @next/next/no-img-element */
import React from 'react'
import { MainLearningCenter } from 'layouts/index'
import { client, FEATURED_POSTS_QUERY, IFeaturedPost, LATEST_POSTS_QUERY } from 'utils/graphql'
import styles from 'pagestyles/blog-index.module.scss'
import { HomeHero } from 'components/HomeHero'
import { Post } from 'components/Post'
import { PostExcerpt } from 'components/Post/PostExcerpt'
import cx from 'classnames'

export interface IndexPageProps {
	posts: any
	featured_posts: IFeaturedPost[]
}

export async function getStaticProps() {
	try {
		const result = await Promise.all([client.query({ query: LATEST_POSTS_QUERY }), client.query({ query: FEATURED_POSTS_QUERY })])
		return {
			props: {
				posts: result[0].data.posts.edges.map((it) => ({ ...it.node })),
				featured_posts: result[1].data.posts.edges.map((it) => ({ ...it.node })),
			},
		}
	} catch (error) {
		console.error(error)
	}

	return {
		props: {
			posts: [],
			featured_posts: [],
		},
	}
}

const IndexPage = (props: IndexPageProps) => {
	return (
		<MainLearningCenter>
			<HomeHero
				href="/blog/autopilot-technology/"
				imgDesktop="https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Tennis+Desktop+Hero.jpeg"
				imgMobile="https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/blog_hero_tennis_mobile.jpg"
				heading="How our powerful technology drives unmatched sleep improvement"
				subtext="Featured post"
			/>
			<div className={'inner-paddings-x outer-margins-y'}>
				<h2 className={styles.heading}>Eight Sleep's Basics</h2>
				<div className={cx('grid', 'test', styles.postslider)}>
					<Post
						post={props.featured_posts[1]}
						style={{ gridColumn: `${(4 * 0 + 1) % 12} / span 4` }}
					/>
					<Post
						post={props.featured_posts[0]}
						style={{ gridColumn: `${(4 * 1 + 1) % 12} / span 4` }}
					/>
					<Post
						post={props.featured_posts[2]}
						style={{ gridColumn: `${(4 * 2 + 1) % 12} / span 4` }}
					/>
				</div>
			</div>
			<div className={cx('outer-margins-y', styles.excerpts)}>
				<div className="inner-paddings-x">
					<h2 className={styles.heading}>Newest Posts</h2>
				</div>
				<div className={styles.posts}>
					{props.posts.map((post, i) => {
						return (
							<PostExcerpt
								post={post}
								key={`newest-post-${i}`}
							/>
						)
					})}
				</div>
			</div>
		</MainLearningCenter>
	)
}

export default IndexPage
