import moment from 'moment'

export const formatPostByDate = (date: string) => {
	const d = new Date(date)
	const formatedDate = moment(d).format('MMMM DD, YYYY')
	return formatedDate
}

export const createMetaData = (title: string, desc: string, image: string, url: string) => {
	const fullUrl = `https://www.eightsleep.com/blog/${url}`
	const description = desc
		? desc
				.split('<div')[0]
				.replaceAll('<p>&nbsp;</p>', '')
				.replace(/(<([^>]+)>)/gi, '')
				.split('.')
				.slice(0, 2)
				.join('.') + '.'
		: ''
	return {
		title: title,
		meta: [
			{ name: 'description', content: description },
			{ itemprop: 'name', content: title },
			{ itemprop: 'description', content: description },
			{ itemprop: 'image', content: image },
			{ property: 'og:url', content: fullUrl },
			{ property: 'og:type', content: 'website' },
			{ property: 'og:title', content: title },
			{ property: 'og:description', content: description },
			{ property: 'og:image', content: image },
			{ name: 'twitter:card', content: 'summary_large_image' },
			{ name: 'twitter:title', content: title },
			{ name: 'twitter:description', content: description },
			{ name: 'twitter:image', content: image },
		],
	}
}
