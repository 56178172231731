import { FC } from 'react'
import styles from './Post.module.scss'
import cx from 'classnames'
import Link from 'next/link'

interface Props {
	post: any
}

export const PostExcerpt: FC<Props> = (props) => {
	const { post } = props
	const articleDate = new Date(post.date).toDateString()
	const pieces = articleDate.split(' ').slice(1)
	const displayDate = pieces[0] + ' ' + pieces[1] + ', ' + pieces[2]
	return (
		<div
			key={`post-${post.id}`}
			className={cx('post_excerpt', styles.post_excerpt)}
		>
			<div className={styles.thumbnail_c}>
				<Link href={`/blog/${post.slug}`}>
					<img
						className={styles.img}
						src={post.featuredImage?.node.sourceUrl}
						alt={post.featuredImage?.node.altText}
					/>
				</Link>
			</div>
			<div className={styles.text}>
				<h3 className={styles.title}>
					<Link href={`/blog/${post.slug}`}>{post.title}</Link>
				</h3>
				<span
					className={styles.html}
					dangerouslySetInnerHTML={{ __html: post.excerpt.split('<div')[0] }}
				></span>
				<small className={styles.date}>{displayDate}</small>
			</div>
		</div>
	)
}
